import { DEFAULT_THEME } from "./../../@theme/styles/theme.default";
import { async } from "@angular/core/testing";
import { MatSnackBar } from "@angular/material";
import { errorCodes } from "./../../shared/constants/constants";
import { ParcAccessService } from "./../../shared/services/parc-access.service";
import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { translate } from "@ngneat/transloco";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MyErrorStateMatcher } from "../../shared/app-classes";
import { AuthService } from "../../shared/services/auth.service";
import { SnackBarComponent } from "../snack-bar/snack-bar.component";
import { DataAccessHelpersService } from "../../shared/services/data-access-helpers.service";
import { element } from "@angular/core/src/render3";
import {NbGlobalPhysicalPosition, NbToastrService} from "@nebular/theme"
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  subUser = new Subscription();
  user: IUser;
  loginForm: FormGroup;
  parcs: any[];
  matcher = new MyErrorStateMatcher();
  roles: any;
  Exist: boolean;
  rolesData: any;
  rolesPermission = [];
  Depot: any;
  fetchedData: any;
  /**

 * Initiate Login form and get all parcs id
 * @param auth  Authentication service
 * @param router Router module
 * @param snackBar Snackbar messages
 * @param pas Parc access service
 */
  constructor(
    private auth: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private pas: ParcAccessService,
    private toaster: NbToastrService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
    this.initializeParcs.bind(this)
  }
  async initializeParcs() {
    try {
      const parcs = await this.pas.getAllParcs();
      if (this.user.displayName === "adminDev") {
        this.parcs = parcs
          .filter((parc) => {
            return !parc.cfs && parc.isDev;
          })
          .map((parc) => parc.id);
      } else {
        this.parcs = parcs
          .filter((parc) => {
            return !parc.cfs && !parc.isDev;
          })
          .map((parc) => parc.id);
      }
    } catch (error) {
      console.error("Error fetching parcs", error);
    }
  }

  /**
   * Redirect to default page according to user role
   * @returns {void}
   */
  async goToDefault() {
    try {
      this.roles = await this.pas.getRolesOnce();
      const rolesUser = this.user.roles;
      this.rolesData = rolesUser;
      this.rolesPermission.push(this.roles.DepotWeb);
      const checker = ([element], rolesPermission) =>
        element !== void 0 ? rolesPermission.includes(element) : true;
      const isAdmin = this.user.roles.includes("admin");
      const isTrucker = this.user.roles.includes("truckerGate");
      const isUser =
        checker(this.rolesData, this.rolesPermission[0]) &&
        !this.user.roles.every((element) => element == "truckerGate");
      const isAuth = isAdmin || isTrucker || isUser;
      if (isAuth) {
        const success =
          localStorage.getItem("language") === "fr"
            ? "Connexion réussie"
            : "Login successful";
        if (isTrucker) {
          this.router.navigate(["user/truckerHome"]);
          this.toaster.show(success, "", {
            duration: 2000,
            hasIcon: false,
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          });
        }
        if (isUser) {
          this.router.navigate(["user/dashboard"]);
          this.toaster.show(success, "", {
            duration: 2000,
            hasIcon: false,
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          });
        }
        if (isAdmin) {
          await this.initializeParcs();
          this.router.navigate([`user/set-up/depots/${this.parcs[0]}`]);
          this.toaster.show(success, "", {
            duration: 2000,
            hasIcon: false,
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          });
        }
      }
      if (!isAuth) {
        const erreur =
          localStorage.getItem("language") === "fr"
            ? "Echec authentification"
            : "Login failed: your user ID or password is incorrect";
        this.toaster.show(erreur, "", {
          duration: 2000,
          hasIcon: false,
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        });
        this.router.navigate(["/login"]);
      }
    } catch (error) {
      console.log("error here pls check 😁",error);
    }
  }

  /**
   * @async
   * Check if email and password matches user credentials
   * @returns {Promise<void>}
   */
  async login(): Promise<void> {
    this.subUser.unsubscribe();
    if (this.loginForm.valid) {
      try {
        await this.auth.doEmailLogin(
          this.loginForm.controls["email"].value,
          this.loginForm.controls["password"].value
        );

        this.subUser = this.auth.user.subscribe(async (u) => {
          if (u) {
            this.user = u;
            await this.goToDefault();
            this.subUser.unsubscribe();
          }
        });
      } catch (error) {
        const message = errorCodes[error.code]
          ? errorCodes[error.code]
          : translate("Echec authentification");
        this.toaster.show(message, "", {
          duration: 2000,
          hasIcon: false,
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        });
      }
    }
  }
  /**
   * Get user and redirect to default page
   * @returns {void}
   */
  ngOnInit(): void {
    this.subUser = this.auth.user.subscribe(async (u) => {
      if (u) {
        this.user = u;
        await this.goToDefault();
      }
    });
  }

  ngOnDestroy(): void {
    this.subUser.unsubscribe();
  }
}
