import { DataAccessHelpersService } from "./data-access-helpers.service";
import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import * as firebase from "firebase";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { translate } from "@ngneat/transloco";
import { SnackBarComponent } from "../../pages/snack-bar/snack-bar.component";
import { environment } from "../../../environments/environment";
import { ParcAccessService } from "./parc-access.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  user: Observable<IUser>;
  redirectRouteSnapshot: ActivatedRouteSnapshot;
  API_ENDPOINT = environment.API_ENDPOINT;
  roles: any;

  headers = { "Content-Type": "application/json" };

  /**
   * Get auth data, then get firestore user document and set it to local storage
   * @param afAuth AngularFirestore authentification
   * @param afs AngularFirestore services
   * @param dhs DataAccessHelpersService
   * @param snackBar Snackbar
   * @param router Navigation and routing
   */
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private dhs: DataAccessHelpersService,
    public snackBar: MatSnackBar,
    private router: Router,
    private pas: ParcAccessService,
    public zone: NgZone
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .doc<IUser>(`users/${user.uid}`)
            .snapshotChanges()
            .pipe(
              map((a) => {
                this.pas.getRoles().subscribe((data) => {
                  this.roles = data.DepotWeb;

                  return this.roles;
                });

                const data = a.payload.data() as any;
                const id = a.payload.id;
                const path = a.payload.ref.path;

                localStorage.setItem(
                  "user",
                  JSON.stringify({ id, path, ...data })
                );

                localStorage.setItem(
                  "userTimezone",
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                );

                if (!localStorage.getItem("language")) {
                  localStorage.setItem("language", "fr");
                }

                return { id, path, ...data };
              })
            );
        } else {
          localStorage.clear();
          return of(false);
        }
      })
    );
  }

  getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken(true);
  }

  /**
   * Send a verification email when register
   */
  doVerifyEmail(): Promise<void> {
    return this.afAuth.auth.currentUser.sendEmailVerification();
  }
  /**
   * Get the current user from firestore
   */
  currentUser(): firebase.User {
    return this.afAuth.auth.currentUser;
  }
  /**
   * Sign in with email and password
   * @param email Email
   * @param password Password
   */
  doEmailLogin(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Register user by email and password
   * @param email Email
   * @param password password
   */
  doRegisterEmailUser(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }
  /**
   * Send password reset to a given email address
   * @param email Email address
   */
  doResetPassword(email: string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
  /**
   * Send user information to server for registration in firestore authentication
   * @param formData User infos
   */



  createUser(formData: FormData): Promise<Response> {

    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/createUser`, {
          headers: { Authorization: idToken },
          method: "POST", // *GET, POST, PUT, DELETE, etc
          body: formData, // body data type must match "Content-Type" header
        });
      })
      .catch((err) => Promise.reject(err));
  }
  
  /**
   * Send user information to server for updating user credentials
   * @param requestBody
   */
  updateUser(requestBody: Partial<IUser>): Promise<void> {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/updateUser`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST", // *GET, POST, PUT, DELETE, etc
          body: JSON.stringify(requestBody), // body data type must match "Content-Type" header
        })
          .then((res) => {
            // this.snackBar.openFromComponent(SnackBarComponent, {
            //   data: {
            //     message: translate("Utilisateur Modifié avec succès"),
            //     icon: "check_circle_outline",
            //   },
            //   panelClass: ["style-success"],
            //   duration: 2000,
            // });
          })
          .catch((err) => {
            this.snackBar.openFromComponent(SnackBarComponent, {
              data: {
                message:
                  translate(`Erreur lors du modification`) + `${err.message}`,
                icon: "block",
              },
              panelClass: ["style-error"],
              duration: 2000,
            });
          });
      })
      .catch((err) => Promise.reject(err));
  }
  /**
   * Get all users that belongs to a given parc param
   * @param value Parc id
   */
  getAllUsers(value: string): Observable<IUser[]> {
    return this.dhs.getCollectionWhere(`users`, [
      { left: "allParcs", operator: "array-contains", right: value },
    ]);
  }
  getAllUsersOnce() {
    return this.dhs.getCollectionOnce(`users`);
  }
  /**
   * Disconnect user from session
   */
  doSignout(): Promise<void> {
    localStorage.clear();
    return this.afAuth.auth.signOut();
  }
  /**
   * Use Google API to sign up
   */
  doGoogleLogin(): Promise<void> {
    const oAuthProvider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(oAuthProvider);
  }
  /**
   * Use Facebook API to sign up
   */
  doFacebookLogin(): Promise<void> {
    const oAuthProvider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(oAuthProvider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user);
        const commands = this.redirectRouteSnapshot.url;
        const queryParams = this.redirectRouteSnapshot.queryParams;
        this.zone.run(() => {
          this.router.navigate(commands, { queryParams });
        });
      })
      .catch((e) => {
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: {
            message: translate("Impossible de vous connecter à votre compte"),
            icon: "block",
          },
          panelClass: ["style-error"],
          duration: 2000,
        });
      });
  }
  /**
   * Update User data
   * @param user User data
   */
  updateUserData(user: firebase.User) {
    // Sets user data to firestore on student-login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    return userRef.set(user);
  }
  /**
   * Update user's profile
   * @param userId User identifier
   * @param data User data
   */
  updateUserInDb(userId: string, data: Partial<IUser>) {
    return this.afs.doc(`users/${userId}`).update(data);
  }
  /**
   * Disconnect user and redirect to Login page
   */
  signOut(): void {
    this.afAuth.auth.signOut().then(() => {
      localStorage.clear();
      this.zone.run(() => {
        this.router.navigate(["/login"]);
      });
    });
  }
  /**
   * Return all Firestore users
   */
  getUsersByType(): Promise<IUser[]> {
    return this.dhs.getCollectionOnce("users");
  }
  /**
   * Returns a promise of user with the same given email address
   * @param email Email address
   */
  getUserByEmail(
    email: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.afs.collection("users").ref.where("email", "==", email).get();
  }
  // edit user as admin
  handleUserEdit(user, uid) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    return userRef.update(user);
  }
  /* Send reset password Via Mail to user */
  sendResetPasswordViaMail(email, userId) {
    const requestBody = {
      email: email,
    };
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/resetPassword`, {
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
          method: "POST", // *GET, POST, PUT, DELETE, etc
          body: JSON.stringify(requestBody), // body data type must match "Content-Type" header
        }).then(async (res) => {
          // update user
          if (res.status === 200) {
            await this.updateUserInDb(userId, { resetPasswordSent: true });
            return { response: true };
          }
        });
      })
      .catch((err) => Promise.reject(err));
  }

  // send welcome email
  sendWelcomeEmail(email, displayName, userType, userId) {
    const requestBody = {
      userEmail: email,
      name: displayName,
      userType: userType,
    };
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/welcomeMail`, {
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
          method: "POST", // *GET, POST, PUT, DELETE, etc
          body: JSON.stringify(requestBody), // body data type must match "Content-Type" header
        }).then(async (res) => {
          if (res.status === 200) {
            // update user
            await this.updateUserInDb(userId, { welcomeEmailSent: true });
            return { response: true };
          }
        });
      })
      .catch((err) => Promise.reject(err));
  }
}
