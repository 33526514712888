import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./pages/login/login.component";
import { AdminAuthGuard } from "./shared/guards/adminAuth.guard";
import { AuthGuard } from "./shared/guards/auth.guard";

const routes: Routes = [
  {
    path: "user",
    canActivate: [AuthGuard],
    loadChildren: "app/pages/pages.module#PagesModule",
  },
  //{ path: 'user', canActivate: [ AdminAuthGuard ], loadChildren: 'app/pages/pages/' },
  {
    path: "auth/login",
    // component: NbAuthComponent,
    children: [
      {
        path: "",
        component: LoginComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
    ],
  },
  { path: "", redirectTo: "user/dashboard", pathMatch: "full" },
  { path: "**", redirectTo: "user/dashboard" },
];
const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
