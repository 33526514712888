import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface Condition {
  left: string;
  operator: any;
  right: any;
}

@Injectable({
  providedIn: "root",
})
export class DataAccessHelpersService {
  constructor(private afs: AngularFirestore) {}
  /**
   * Returns an observable of a firestore document
   * @param docPath Firestore document path
   */
  getDocument(docPath: string): Observable<any> {
    return this.afs
      .doc(docPath)
      .snapshotChanges()
      .pipe(
        map((a) => {
          const data = a.payload.data() as any;
          const id = a.payload.id;
          const path = a.payload.ref.path;
          return { id, path, ...data };
        })
      );
  }
  /**
   * Returns a Promise of a firestore collection
   * @param collectionPath Firestore collection path
   */
  getCollectionOnce(collectionPath: string): Promise<any[]> {
    return this.afs
      .collection(collectionPath)
      .ref.get()
      .then((docs) => {
        const result = [];
        docs.forEach((doc) => {
          const id = doc.id;
          const data = doc.data() as any;
          const path = doc.ref.path;
          result.push({ id, path, ...data });
        });
        return result;
      });
  }


  getCollectionOnceOrderBy(collectionPath: string, queryFn?: (ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>) => firebase.firestore.Query<firebase.firestore.DocumentData>): Promise<any[]> {
    let collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData> = this.afs.collection(collectionPath).ref;
    if (queryFn) {
        collectionRef = queryFn(collectionRef as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>);
    }
    return collectionRef.get().then((docs) => {
        const result: any[] = [];
        docs.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            const path = doc.ref.path;
            result.push({ id, path, ...data });
        });
        return result;
    });
}
  /**
   * Returns a Promise of a firestore document
   * @param docPath Firestore document path
   */
  getDocumentOnce(docPath: string): Promise<any> {
    return this.afs
      .doc(docPath)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        const id = doc.id;
        const path = doc.ref.path;
        return { id, path, ...data };
      });
  }
  /**
   * Returns an observable of a firestore collection
   * @param coPath Firestore collection path
   */
  getCollection(coPath: string): Observable<any[]> {
    return this.afs
      .collection<any>(coPath)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            const path = a.payload.doc.ref.path;
            return { id, path, ...data };
          });
        })
      );
  }
  getCollectionOrderBy(
    coPath: string,
    orderBy: string,
    direc: firebase.firestore.OrderByDirection
  ): Observable<any[]> {
    return this.afs
      .collection<any>(coPath, (ref) => {
        if (orderBy) {
          return ref.orderBy(orderBy, direc);
        } else {
          return ref;
        }
      })
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            const path = a.payload.doc.ref.path;
            return { id, path, ...data };
          });
        })
      );
  }
  /**
   * Returns an observable of a firestore collection with a given condition(s)
   * @param coPath Firestore collection path
   * @param conditions Array of conditions (Queries)
   */
  getCollectionWhere(
    coPath: string,
    conditions: Condition[]
  ): Observable<any[]> {
    return this.afs
      .collection<any>(coPath, (ref) => {
        const cnd0 = conditions.pop();
        if (cnd0) {
          let query = ref.where(cnd0.left, cnd0.operator, cnd0.right);
          conditions.forEach(({ left, operator, right }: Condition) => {
            query = query.where(left, operator, right);
          });
          return query;
        } else {
          return ref;
        }
      })
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            const path = a.payload.doc.ref.path;
            return { id, path, ...data };
          });
        })
      );
  }
  getCollectionWhereByOrder(
    coPath: string,
    conditions: Condition[],
    orderBy: string,
    direc: firebase.firestore.OrderByDirection
  ): Observable<any[]> {
    return this.afs
      .collection<any>(coPath, (ref) => {
        const cnd0 = conditions.pop();
        if (cnd0) {
          let query = ref.where(cnd0.left, cnd0.operator, cnd0.right);
          conditions.forEach(({ left, operator, right }: Condition) => {
            query = query.where(left, operator, right);
          });
          query = query.orderBy(orderBy, direc);
          return query;
        } else {
          return ref;
        }
      })
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            const path = a.payload.doc.ref.path;
            return { id, path, ...data };
          });
        })
      );
  }

  getCollectionWhereByOrderWithLimit(
    coPath: string,
    conditions: Condition[],
    orderBy: string,
    direc: firebase.firestore.OrderByDirection,
    limit: number
  ): Observable<any[]> {
    return this.afs
      .collection<any>(coPath, (ref) => {
        const cnd0 = conditions.pop();
        if (cnd0) {
          let query = ref.where(cnd0.left, cnd0.operator, cnd0.right);
          conditions.forEach(({ left, operator, right }: Condition) => {
            query = query.where(left, operator, right);
          });
          query = query.orderBy(orderBy, direc);
          query = query.limit(limit);
          return query;
        } else {
          return ref;
        }
      })
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            const path = a.payload.doc.ref.path;
            return { id, path, ...data };
          });
        })
      );
  }
}
