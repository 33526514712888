import { DevisService } from "./../../../shared/services/devis.service";
import { Component, Input, OnInit, OnDestroy, ViewChild } from "@angular/core";
import {
  NbMenuService,
  NbSidebarService,
  NbSearchService,
  NbToastrService,
} from "@nebular/theme";
import { filter, map } from "rxjs/operators";

import { MatMenuTrigger } from "@angular/material";
import { Router } from "@angular/router";
import { LayoutService } from "../../../@core/utils";
import { AuthService } from "../../../shared/services/auth.service";
import { Subscription } from "rxjs";
import { ConteneurAccessService } from "../../../shared/services/conteneur-access.service";
import { TranslocoService } from "@ngneat/transloco";
import { ParcAccessService } from "../../../shared/services/parc-access.service";
import { translate } from "@ngneat/transloco";
import { shipOwnerLogo } from "../../../shared/constants/constants";
import { Row } from "jspdf-autotable";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  @Input() position = "normal";

  authUser: IUser;
  value = "";
  notifications: INotification[] = [];
  unReadNotification: number;
  subSign: Subscription;
  subNotif: Subscription;
  subOnSubmit: Subscription;
  userMenu = [{ title: "Déconnexion" }];
  notificationMenu = [{ title: "Notifcation1" }];
  lng: string;
  mySubscription: any;
  logoLiner: string = "";
  notifs = [];
  isTruckerGate: boolean = false;
  chassisCapabilities: boolean = false;
  constructor(
    private pas: ParcAccessService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    public toaster: NbToastrService,
    private auth: AuthService,
    private router: Router,
    private cas: ConteneurAccessService,
    private layoutService: LayoutService,
    private searchService: NbSearchService,
    private das: DevisService,
    public translateService: TranslocoService
  ) {
    this.authUser = JSON.parse(localStorage.getItem("user"));
    this.lng = this.translateService.getActiveLang();

    if (this.authUser["roles"].includes("liner")) {
      for (const key in shipOwnerLogo) {
        if (Object.prototype.hasOwnProperty.call(shipOwnerLogo, key)) {
          const element = shipOwnerLogo[key];
          this.authUser["liner"] === key ? (this.logoLiner = element) : null;
        }
      }
    }
  }
  /**
   * Get all manager notifications and subscribe to logout context menu
   */
  async ngOnInit() {
    try {
      if (this.authUser["roles"].includes("truckerGate")) {
        this.isTruckerGate = true;
      }
      const { allParcs } = JSON.parse(localStorage.getItem("user"));
      const selectedParc = localStorage.getItem("selectedParcs")
        ? localStorage.getItem("selectedParcs")
        : allParcs[0];
      const parcData = await this.pas.getYardOnceById(selectedParc);
      this.chassisCapabilities = parcData["chassisCapabilities"] ? true : false;
      this.subNotif = this.das
        .getNotifications(this.authUser.id)
        .subscribe((notif) => {
          this.notifications = notif;
          this.notifications = this.notifications.map((data) => {
            return {
              ...data,
              status: data.status.includes("Veuillez")
                ? `${translate(data.status)}`
                : data.status.includes("Attention")
                ? translate(
                    `Attention, la pièce de réf. $$$ a atteint le seuil d'alerte de £££ unité(s). Merci de réapprovisionner le stock.`
                  )
                    .replace(
                      "$$$",
                      data.status
                        .split("réf.")[1]
                        .split(" a atteint le seuil d'alerte de ")[0]
                    )
                    .replace(
                      "£££",
                      data.status
                        .split("réf.")[1]
                        .split(" a atteint le seuil d'alerte de ")[1]
                        .split(" unité(s).")[0]
                    )
                : data.status.includes("Un OCR")
                ? translate(data.status)
                : data.status,
            };
          });

          this.notifs = this.notifications.sort(
            (x, y) => +new Date(y.createdAt) - +new Date(x.createdAt)
          );
        });

      this.subSign = this.menuService
        .onItemClick()
        .pipe(
          filter(({ tag }) => tag === "user-context-menu"),
          map(({ item: { title } }) => title)
        )
        .subscribe((title) => {
          if (title === "Déconnexion") {
            this.logout();
          }
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  /**
   * Disconnect and redirect to Login page
   */
  async logout() {
    await this.auth.doSignout();
    // this.toaster.show("Vous avez été déconnecté de votre compte", "", {
    //   duration: 2000,
    //   icon: "nb-arrow",
    //   hasIcon: true,
    // });
    await this.router.navigate(["/auth/login"]);
  }
  /**
   * Update notification to isRead after clicked and redirect to notification subject
   * @param notif Selected notification
   */
  redirect(notif: INotification) {
    //this.das.updateNotifications(this.authUser.id, notif.id, { isRead: true });
    if (
      notif.status.includes("Veuillez") ||
      notif.status.includes("Please complete")
    ) {
      const { allParcs } = JSON.parse(localStorage.getItem("user"));
      const selectedParc = localStorage.getItem("selectedParcs")
        ? localStorage.getItem("selectedParcs")
        : allParcs[0];
      this.pas.getIncompletedGates(selectedParc).subscribe((data) => {
        let Obj = {};
        for (let doc of data) {
          if (doc.hasOwnProperty("remarque") && !this.chassisCapabilities) {
            delete doc.remarque;
          }
        }
        let ObjResult = data.filter((el) =>
          el.numSerie ? el.numSerie == notif.id : el.chassisNumber == notif.id
        );

        Obj = ObjResult[0];
        if (this.chassisCapabilities) {
          this.router.navigate(["/user/gate-in-chassis"], {
            state: { row: Obj },
          });
        } else {
          this.router.navigate(["/user/gate-in"], { state: { row: Obj } });
        }
      });
    } else if (notif.status.toLowerCase().includes("ocr")) {
      this.router.navigate(["/user/gate-in"]);
    } else {
      this.router.navigate(["/user/stockInventory"]);
    }
  }

  deleteNotification(notif: INotification) {
    this.das.deleteNotification(this.authUser.id, notif.id);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }
  /**
   * Search for a container in yard plan by his serial number
   */
  startSearch() {
    const parcs = this.authUser.allParcs;
    this.subOnSubmit = this.searchService
      .onSearchSubmit()
      .subscribe(async (data: { term: string; tag?: string }) => {
        this.value = data.term;
        for (let index = 0; index < parcs.length; index++) {
          const parc = parcs[index];
          const isCtr = await this.cas.getContainerData(
            parc,
            this.value.toUpperCase()
          );
          const isChassis = await this.cas.getChassisData(
            parc,
            this.value.toUpperCase()
          );
          const parcData = await this.pas.getYardOnceById(parc);

          if (isCtr.id && parcData["yardless"]) {
            this.router.navigate(["/user/reports/details"], {
              queryParams: { numSerie: this.value.toUpperCase(), parc },
              preserveQueryParams: false,
            });
            break;
          }
          if (isCtr.position && isCtr.position.indexOf("-") > -1) {
            this.router.navigate(["/user/plan"], {
              queryParams: { con: isCtr.id, parc },
              preserveQueryParams: false,
            });
            break;
          }
          if (isChassis.id && parcData["yardless"]) {
            this.router.navigate(["/user/reports/details"], {
              queryParams: { numSerie: this.value.toUpperCase(), parc },
              preserveQueryParams: false,
            });
            break;
          }
          if (isChassis.position && isChassis.position.indexOf("-") > -1) {
            this.router.navigate(["/user/plan"], {
              queryParams: { con: isChassis.id, parc },
              preserveQueryParams: false,
            });
            break;
          }
          if (index === parcs.length - 1) {
            this.router.navigate(["/user/parcs"], {
              queryParams: { con: this.value },
            });
          }
        }
      });
  }
  reloadComponent() {
    if (this.authUser["roles"].includes("admin")) {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate([currentUrl]);
    } else {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate([currentUrl]);
    }
  }

  updateFrLanguage(ln) {
    localStorage.setItem("language", ln);
    this.lng = ln;
    this.translateService.setActiveLang(ln);
    this.translateService.langChanges$.subscribe((lang) =>
      this.reloadComponent()
    );
  }

  /**
   * Unsubscribe all subscriptions
   */
  ngOnDestroy() {
    this.subNotif.unsubscribe();
    this.subSign.unsubscribe();
    if (this.subOnSubmit) {
      this.subOnSubmit.unsubscribe();
    }
  }
}
