import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { translate } from "@ngneat/transloco";

import { MatSnackBar } from "@angular/material";
import { map, take, tap } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { SnackBarComponent } from "../../pages/snack-bar/snack-bar.component";

@Injectable({
  providedIn: "root",
})
export class AdminAuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const roleInURL = next.url[0].path;
    let otherRole = false;
    return this.auth.user.pipe(
      take(1),
      map((user) => {
        if (!user || !user.roles) {
          this.router.navigate(["/auth/login"]);
          return false;
        } else if (user && user.roles.includes("admin")) {
          return true;
        } else if (user) {
          otherRole = true;
          return false;
        } else {
          this.router.navigate(["/auth/login"]);
          return false;
        }
      }),
      tap((loggedIn) => {
        if (!loggedIn) {
          if (otherRole) {
            this.auth.signOut();
            this.snackBar.openFromComponent(SnackBarComponent, {
              data: {
                message: translate(
                  "Access Denied Try your credentials in Mobile App"
                ),
                icon: "check_circle_outline",
              },
              panelClass: ["style-success"],
              duration: 2000,
            });
          }
          this.auth.redirectRouteSnapshot = next;
          this.router.navigate(["/auth/login"]);
        }
      })
    );
  }
}
