import { NbMenuItem } from "@nebular/theme";
import isoCodes from "../../../assets/data/iso-codes.json";
import { translate } from "@ngneat/transloco";

export const ReportMenu = {
  title: "Rapports",
  icon: "nb-list",
  children: [
    {
      title: "Gates",
      link: "/user/reports/gates",
    },
    {
      title: "Gate in",
      link: "/user/reports/gate-in",
    },
    {
      title: "Gate out",
      link: "/user/reports/gate-out",
    },
    {
      title: "Mouvements Parasites",
      link: "/user/reports/parasites",
    },
    {
      title: "Gates par créneau horaire",
      link: "/user/reports/timeline",
    },
    {
      title: "Réparations",
      link: "/user/reports/repairs",
    },
    {
      title: "Historique PTI",
      link: "/user/reports/PTIlogs",
    },
    {
      title: "Historique lavages",
      link: "/user/reports/cleaninglogs",
    },
    {
      title: "Taches EMR+VAS par jour",
      link: "/user/reports/tasks",
    },
    {
      title: "Opérations facturables (accords)",
      link: "/user/reports/turnover",
    },
    {
      title: "Opérations facturables CMA",
      link: "/user/reports/turnover-cma",
    },
    {
      title: "Opérations facturables ONE",
      link: "/user/reports/turnover-one",
    },
    {
      title: "Opérations facturables AMAZON",
      link: "/user/reports/turnover-amazon",
    },
    {
      title: "Opérations facturables (réparations)",
      link: "/user/reports/turnover-ascot",
    },
    {
      title: "Historique pièces utilisées",
      link: "/user/reports/pieces",
    },
    {
      title: "Commandes réceptionnées",
      link: "/user/reports/orders",
    },
    {
      title: "Consommation gaz",
      link: "/user/reports/gasConsumption",
    },
    {
      title: "Activités Transporteurs",
      link: "/user/reports/transportersActivity",
    },
    {
      title: "Dépilement/Rempilement",
      link: "/user/reports/stackingUnstacking",
    },
    {
      title: "Listes Positioning",
      link: "/user/reports/positioningList",
    },
    {
      title: "Historique Devis",
      link: "/user/reports/billsHistory",
    },
    {
      title: "Activités équipe",
      link: "/user/reports/teamActivity",
    },
    {
      title: "Rapport envoi EDI",
      link: "/user/reports/mail-report",
    },
    {
      title: "Rapport turn time",
      link: "/user/reports/turntime",
    },
    {
      title: "Bilan carbone",
      link: "/user/reports/Carbon",
    },
  ],
};
export const allMenu = (
  authorizedReports,
  count,
  countRejected
): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: "Vue Parcs",
    link: "/user/parcs",
    icon: "nb-grid-b-outline",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "Gates",
    icon: "nb-arrow-retweet",
    children: [
      {
        title: "Entrées",
        link: "/user/gate-in",
        icon: "nb-arrow-thin-right",
      },
      {
        title: `A compléter (${count})`,
        link: "/user/incompleteGates",
        icon: "nb-alert",
      },
      {
        title: "Sorties",
        link: "/user/gate-out",
        icon: "nb-arrow-thin-left",
      },
      {
        title: "Barges/Trains",
        link: "/user/pre-notifs-trains",
        icon: "nb-angle-double-right",
      },
      {
        title: "Positioning/Evacuation",
        link: "/user/pre-notifs",
        icon: "nb-angle-double-right",
      },
      {
        title: "",
        link: "/user/pre-advice",
        icon: "nb-angle-double-right",
      },
      {
        title: "Booking",
        link: "/user/booking",
        icon: "nb-compose",
      },
      // {
      //   title: "Bundling",
      //   link: "/user/bundling-chassis",
      //   icon: "nb-compose",
      // },
      {
        title: "Interchanges",
        icon: "nb-list",
        expanded: false,
        children: [
          {
            title: "En attente",
            link: "/user/interchanges/pending",
            icon: "nb-arrow-thin-right",
          },
          {
            title: "Prêts à imprimer",
            link: "/user/interchanges/done",
            icon: "nb-checkmark-circle",
          },
        ],
      },
    ],
  },
  {
    title: "EMR",
    icon: "nb-e-commerce",
    children: [
      {
        title: "Devis en cours",
        link: "/user/devis/pending",
      },
      /*   {
        title: "Devis acceptés",
        link: "/user/devis/accepted",
      }, */
      {
        title: `Devis refusés (${countRejected})`,
        link: "/user/devis/refused",
      },
      /* {
        title: "PTI",
        link: "/user/pti",
      },
             {
        title: "Lavage",
        link: "/user/cleaning",
      }, */
      {
        title: "Accords lignes",
        link: "/user/approvals",
      },
    ],
  },
  {
    title: "Pièces détachées",
    icon: "nb-gear",
    children: [
      {
        title: "Stock",
        link: "/user/stockInventory",
      },
      {
        title: "Catalogues",
        link: "/user/catalogs",
      },
      {
        title: "Gestion des commandes",
        children: [
          {
            title: "Nouvelle(s) commande(s)",
            link: "/user/newOrders",
          },
          {
            title: "Réception commande(s)",
            link: "/user/listOrders",
          },
        ],
      },
      {
        title: "Historique pièces",
        link: "/user/pieceHistory",
      },
    ],
  },
  {
    title: "Inventaire",
    link: "/user/reports/inventory",
    icon: "nb-menu",
  },

  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];
export const adminMenu: NbMenuItem[] = [
  // {
  //   title: "Configurer nouveau dépôt",
  //   link: "/user/set-up/yard",
  //   icon: "nb-plus",
  // },
  {
    title: "Configurer nouveau dépôt",
    link: "/user/addNewYard",
    icon: "nb-plus",
  },
  {
    title: "Ajouter nouveau client Shipzzer",
    link: "/user/customers",
    icon: "nb-keypad",
  },
  {
    title: "Paramètres",
    link: "/user/set-up/",
    icon: "nb-gear",
  },
  {
    title: "Taux de change",
    link: "/user/set-up/exchange-rate",
    icon: "nb-bar-chart",
  },
  {
    title: "Rôles dépôt",
    link: "/user/roles",
    icon: "nb-person",
  },
  {
    title: "Rapports par rôle dépôt",
    link: "/user/reports",
    icon: "nb-list",
  },
  {
    title: "Inventaire",
    link: "/user/inventoryList",
    icon: "nb-compose",
  },
  {
    title: "Journaux",
    link: "/user/log",
    icon: "nb-search",
  },
  /* {
    title: "VBS",
    icon: "nb-compose",
    link: "/user/vbs",
  } */
];
export const adminMenuEN: NbMenuItem[] = [
  // {
  //   title: "Configurer nouveau dépôt",
  //   link: "/user/set-up/yard",
  //   icon: "nb-plus",
  // },
  { title: "Set-up new yard", link: "addNewYard", icon: "nb-plus" },
  {
    title: "Add new Shipzzer customer",
    link: "customers",
    icon: "nb-keypad",
  },
  {
    title: "Settings",
    link: "/user/set-up/",
    icon: "nb-gear",
  },
  {
    title: "Exchange rate",
    link: "/user/set-up/exchange-rate",
    icon: "nb-bar-chart",
  },
  {
    title: "Yard roles",
    link: "roles",
    icon: "nb-person",
  },
  {
    title: "Reports by yard role",
    link: "reports",
    icon: "nb-list",
  },
  {
    title: "Inventory",
    link: "inventoryList",
    icon: "nb-compose",
  },
  {
    title: "Logs",
    link: "log",
    icon: "nb-search",
  },
  /* {
    title: "VBS",
    icon: "nb-compose",
    link: "/user/vbs",
  } */
];
export const emrLiteWeb = (authorizedReports, countRejected): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "EMR",
    icon: "nb-e-commerce",
    children: [
      {
        title: "Devis en cours",
        link: "/user/devis/pending",
      },

      {
        title: `Devis refusés (${countRejected})`,
        link: "/user/devis/refused",
      },

      /*       {
        title: "Accords lignes",
        link: "/user/approvals",
      }, */
    ],
  },
  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];

export const linerMenu = (authorizedReports): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "EMR",
    icon: "nb-e-commerce",
    children: [
      {
        title: "Devis en cours",
        link: "/user/devis/pending",
      },
    ],
  },

  {
    title: "Inventaire",
    link: "/user/reports/inventory",
    icon: "nb-tables",
  },
  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];
export const gateMenu = (count): NbMenuItem[] => [
  {
    title: "Vue Parc",
    icon: "nb-grid-b-outline",
    link: "/user/parcs",
    home: true,
  },

  {
    title: "Gates",
    icon: "nb-arrow-retweet",
    children: [
      {
        title: "Entrées",
        link: "/user/gate-in",
        icon: "nb-arrow-thin-right",
      },
      {
        title: `A compléter  (${count})`,
        link: "/user/incompleteGates",
        icon: "nb-alert",
      },
      {
        title: "Sorties",
        link: "/user/gate-out",
        icon: "nb-arrow-thin-left",
      },
      {
        title: "Barges/Trains",
        link: "/user/pre-notifs-trains",
        icon: "nb-angle-double-right",
      },
      {
        title: "Positioning/Evacuation",
        link: "/user/pre-notifs",
        icon: "nb-angle-double-right",
      },
      {
        title: "Pre advice",
        link: "/user/pre-advice",
        icon: "nb-angle-double-right",
      },
      {
        title: "Booking",
        link: "/user/booking",
        icon: "nb-compose",
      },
      // {
      //   title: "Bundling",
      //   link: "/user/bundling-chassis",
      //   icon: "nb-compose",
      // },
      {
        title: "Interchanges",
        icon: "nb-list",
        expanded: false,
        children: [
          {
            title: "En attente",
            link: "/user/interchanges/pending",
            icon: "nb-arrow-thin-right",
          },
          {
            title: "Prêts à imprimer",
            link: "/user/interchanges/done",
            icon: "nb-checkmark-circle",
          },
        ],
      },

      {
        title: "VBS",
        link: "/user/appointment",
        icon: "nb-location",
      },
    ],
  },
];
export const truckerGateMenu: NbMenuItem[] = [
  {
    title: "Home",
    link: "/user/truckerHome",
    icon: "nb-person",
    home: true,
  },
];
export const gateCoordinatorMenu = (count, authorizedReports): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: "Vue Parcs",
    link: "/user/parcs",
    icon: "nb-grid-b-outline",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "Gates",
    icon: "nb-arrow-retweet",
    children: [
      {
        title: "Entrées",
        link: "/user/gate-in",
        icon: "nb-arrow-thin-right",
      },
      {
        title: `A compléter  (${count})`,
        link: "/user/incompleteGates",
        icon: "nb-alert",
      },
      {
        title: "Sorties",
        link: "/user/gate-out",
        icon: "nb-arrow-thin-left",
      },
      {
        title: "Barges/Trains",
        link: "/user/pre-notifs-trains",
        icon: "nb-angle-double-right",
      },
      {
        title: "Positioning/Evacuation",
        link: "/user/pre-notifs",
        icon: "nb-angle-double-right",
      },
      {
        title: "Pre advice",
        link: "/user/pre-advice",
        icon: "nb-angle-double-right",
      },
      {
        title: "Booking",
        link: "/user/booking",
        icon: "nb-compose",
      },
      {
        title: "Interchanges",
        icon: "nb-list",
        expanded: false,
        children: [
          {
            title: "En attente",
            link: "/user/interchanges/pending",
            icon: "nb-arrow-thin-right",
          },
          {
            title: "Prêts à imprimer",
            link: "/user/interchanges/done",
            icon: "nb-checkmark-circle",
          },
        ],
      },
      {
        title: "VBS",
        link: "/user/appointment",
        icon: "nb-location",
      },
    ],
  },
  {
    title: "Inventaire",
    link: "/user/reports/inventory",
    icon: "nb-menu",
  },
  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];

export const emrCoordinatorMenu = (
  authorizedReports,
  countRejected
): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: "Vue Parcs",
    link: "/user/parcs",
    icon: "nb-grid-b-outline",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "EMR",
    icon: "nb-e-commerce",
    children: [
      {
        title: "Devis en cours",
        link: "/user/devis/pending",
      },
      /*  {
        title: "Devis acceptés",
        link: "/user/devis/accepted",
      }, */
      {
        title: `Devis refusés (${countRejected})`,
        link: "/user/devis/refused",
      },
      /* {
        title: "PTI",
        link: "/user/pti",
      },
         {
        title: "Lavage",
        link: "/user/cleaning",
      }, */
      {
        title: "Accords lignes",
        link: "/user/approvals",
      },
    ],
  },

  {
    title: "Pièces détachées",
    icon: "nb-gear",
    children: [
      {
        title: "Stock",
        link: "/user/stockInventory",
      },
      {
        title: "Catalogues",
        link: "/user/catalogs",
      },
      {
        title: "Gestion des commandes",
        children: [
          {
            title: "Nouvelle(s) commande(s)",
            link: "/user/newOrders",
          },
          {
            title: "Réception commande(s)",
            link: "/user/listOrders",
          },
        ],
      },
      {
        title: "Historique pièces",
        link: "/user/pieceHistory",
      },
    ],
  },
  {
    title: "Inventaire",
    link: "/user/reports/inventory",
    icon: "nb-menu",
  },
  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];

export const gateAndemrCoordinatorMenu = (
  count,
  authorizedReports,
  countRejected
): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: "Vue Parcs",
    link: "/user/parcs",
    icon: "nb-grid-b-outline",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "Gates",
    icon: "nb-arrow-retweet",
    children: [
      {
        title: "Entrées",
        link: "/user/gate-in",
        icon: "nb-arrow-thin-right",
      },
      {
        title: `A compléter  (${count})`,
        link: "/user/incompleteGates",
        icon: "nb-alert",
      },
      {
        title: "Sorties",
        link: "/user/gate-out",
        icon: "nb-arrow-thin-left",
      },
      {
        title: "Barges/Trains",
        link: "/user/pre-notifs-trains",
        icon: "nb-angle-double-right",
      },
      {
        title: "Positioning/Evacuation",
        link: "/user/pre-notifs",
        icon: "nb-angle-double-right",
      },
      {
        title: "Pre advice",
        link: "/user/pre-advice",
        icon: "nb-angle-double-right",
      },
      {
        title: "Booking",
        link: "/user/booking",
        icon: "nb-compose",
      },
      // {
      //   title: "Bundling",
      //   link: "/user/bundling-chassis",
      //   icon: "nb-compose",
      // },
      {
        title: "Interchanges",
        icon: "nb-list",
        expanded: false,
        children: [
          {
            title: "En attente",
            link: "/user/interchanges/pending",
            icon: "nb-arrow-thin-right",
          },
          {
            title: "Prêts à imprimer",
            link: "/user/interchanges/done",
            icon: "nb-checkmark-circle",
          },
        ],
      },
      {
        title: "VBS",
        link: "/user/appointment",
        icon: "nb-location",
      },
    ],
  },
  {
    title: "EMR",
    icon: "nb-e-commerce",
    children: [
      {
        title: "Devis en cours",
        link: "/user/devis/pending",
      },
      /*  {
          title: "Devis acceptés",
          link: "/user/devis/accepted",
        }, */
      {
        title: `Devis refusés (${countRejected})`,
        link: "/user/devis/refused",
      },
      /* {
          title: "PTI",
          link: "/user/pti",
        },
          {
          title: "Lavage",
          link: "/user/cleaning",
        }, */
      {
        title: "Accords lignes",
        link: "/user/approvals",
      },
    ],
  },
  {
    title: "Pièces détachées",
    icon: "nb-gear",
    children: [
      {
        title: "Stock",
        link: "/user/stockInventory",
      },
      {
        title: "Catalogues",
        link: "/user/catalogs",
      },
      {
        title: "Gestion des commandes",
        children: [
          {
            title: "Nouvelle(s) commande(s)",
            link: "/user/newOrders",
          },
          {
            title: "Réception commande(s)",
            link: "/user/listOrders",
          },
        ],
      },
      {
        title: "Historique pièces",
        link: "/user/pieceHistory",
      },
    ],
  },

  {
    title: "Inventaire",
    link: "/user/reports/inventory",
    icon: "nb-menu",
  },
  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];

export const managerMenu = (
  count,
  authorizedReports,
  countRejected
): NbMenuItem[] => [
  {
    title: "Tableau de bord",
    icon: "nb-bar-chart",
    link: "/user/dashboard",
  },
  {
    title: "Vue Parcs",
    link: "/user/parcs",
    icon: "nb-grid-b-outline",
  },
  {
    title: `Historique opérations`,
    link: "/user/reports/details",
    icon: "nb-search",
  },
  {
    title: "Gates",
    icon: "nb-arrow-retweet",
    children: [
      {
        title: "Entrées",
        link: "/user/gate-in",
        icon: "nb-arrow-thin-right",
      },
      {
        title: `A compléter  (${count})`,
        link: "/user/incompleteGates",
        icon: "nb-alert",
      },
      {
        title: "Sorties",
        link: "/user/gate-out",
        icon: "nb-arrow-thin-left",
      },
      {
        title: "Barges/Trains",
        link: "/user/pre-notifs-trains",
        icon: "nb-angle-double-right",
      },
      {
        title: "Positioning/Evacuation",
        link: "/user/pre-notifs",
        icon: "nb-angle-double-right",
      },
      {
        title: "Pre advice",
        link: "/user/pre-advice",
        icon: "nb-angle-double-right",
      },
      {
        title: "Booking",
        link: "/user/booking",
        icon: "nb-compose",
      },
      /*    {
        title: "Bundling",
        link: "/user/bundling-chassis",
        icon: "nb-collapse",
      }, */
      {
        title: "Interchanges",
        icon: "nb-list",
        expanded: false,
        children: [
          {
            title: "En attente",
            link: "/user/interchanges/pending",
            icon: "nb-arrow-thin-right",
          },
          {
            title: "Prêts à imprimer",
            link: "/user/interchanges/done",
            icon: "nb-checkmark-circle",
          },
        ],
      },

      {
        title: "VBS",
        link: "/user/appointment",
        icon: "nb-location",
      },
    ],
  },
  {
    title: "EMR",
    icon: "nb-e-commerce",
    children: [
      {
        title: "Devis en cours",
        link: "/user/devis/pending",
      },
      /*  {
        title: "Devis acceptés",
        link: "/user/devis/accepted",
      }, */
      {
        title: `Devis refusés (${countRejected})`,
        link: "/user/devis/refused",
      },
      /* {
        title: "PTI",
        link: "/user/pti",
      },
        {
        title: "Lavage",
        link: "/user/cleaning",
      }, */
      {
        title: "Accords lignes",
        link: "/user/approvals",
      },
    ],
  },
  {
    title: "Pièces détachées",
    icon: "nb-gear",
    children: [
      {
        title: "Stock",
        link: "/user/stockInventory",
      },

      {
        title: "Catalogues",
        link: "/user/catalogs",
      },
      {
        title: "Gestion des commandes",
        children: [
          {
            title: "Nouvelle(s) commande(s)",
            link: "/user/newOrders",
          },
          {
            title: "Réception commande(s)",
            link: "/user/listOrders",
          },
        ],
      },
      {
        title: "VenteCtr",
        children: [
          {
            title: "Vente conteneur(s)",
            link: "/user/containerSale",
          },
          {
            title: "Vente pièce(s)",
            link: "/user/pieceSale",
          },
        ],
      },
      {
        title: "Historique pièces",
        link: "/user/pieceHistory",
      },
    ],
  },

  {
    title: "Inventaire",
    link: "/user/reports/inventory",
    icon: "nb-menu",
  },
  {
    title: "Rapports",
    icon: "nb-list",
    children: authorizedReports,
  },
];
export const Armateurs = [
  "MSK",
  "ALT",
  "ARN",
  "CMA",
  "MSC",
  "HPL",
  "OCL",
  "MAR",
  "ONE",
  "EVE",
  "YML",
  "HMM",
  "CSO",
  "WEC",
  "HON",
  "NOR",
  "REM",
  "CPT",
  "HBB",
  "COL",
  "BLU",
  "SEA",
  "ZIM",
  "MEL",
  "GMP",
  "OTH",
  "CAR",
];

export default {
  MATCHINGCODES: isoCodes,
  CURRENCIES: { USD: "$", TND: "TND", Eur: "€", GBP: "£" },
  ISOCODES: Object.keys(isoCodes),
  TYPES: Array.from(
    new Set(
      Object.values(isoCodes).map((item) => {
        return item["type"];
      })
    )
  ),
  SIZES: Array.from(
    new Set(
      Object.values(isoCodes).map((item) => {
        return item["size"];
      })
    )
  ),
  ASSIGNMENTLETTERS: {
    A: 10,
    B: 12,
    C: 13,
    D: 14,
    E: 15,
    F: 16,
    G: 17,
    H: 18,
    I: 19,
    J: 20,
    K: 21,
    L: 23,
    M: 24,
    N: 25,
    O: 26,
    P: 27,
    Q: 28,
    R: 29,
    S: 30,
    T: 31,
    U: 32,
    V: 34,
    W: 35,
    X: 36,
    Y: 37,
    Z: 38,
  },

  ARMATEURS: [
    "ARN",
    "MSK",
    "ALT",
    "CMA",
    "MSC",
    "HPL",
    "OCL",
    "MAR",
    "ONE",
    "EVE",
    "YML",
    "HMM",
    "CSO",
    "WEC",
    "HON",
    "NOR",
    "REM",
    "CPT",
    "HBB",
    "COL",
    "BLU",
    "SEA",
    "ZIM",
    "MEL",
    "GMP",
    "OTH",
    "CAR",
  ],
  ETATS: {
    AI: "A inspecter",
    D: "Disponible",
    B: "Bloqué",
    R: "Réparé",
    E: "Endommagé",
    DS: "Devisé",
    ER: "En réparation",
    AP: "Accord Partiel",
    EX: "Expertisé",
    DR: "Devis refusé",
    P: "Plein",
    "PTI to be done": "PTI à faire",
    "PTI done": "PTI fait",
  },
  // PERIODS: ["Ce jour", "J-7", "J-30", "Intervalle"],
  PERIODS: ["Ce jour", "J-7", "J-1er", "J-30"],
  LISTREPORTS: [
    "Gate-In",
    "Gate-Out",
    "Mouvements Parasites",
    "Historique PTI",
    "Historique lavages",
    "Gates",
    "Gates par créneau horaire",
    "Réparations",
    //"Taches EMR+VAS par jour",
    "Opérations facturables (accords)",
    "Opérations facturables (réparations)",
    "Historique pièces utilisées",
    "Opérations facturables CMA",
    "Opérations facturables AMAZON",
    "Opérations facturables ONE",
    "Listes Positioning",
    "Rapport turn time",
    "Dépilement/Rempilement",
    "Rapport envoi EDI",
    "Activités équipe",
    "Historique Devis",
    "Activités Transporteurs",
    "Consommation gaz",
    "Commandes réceptionnées",
  ],
  PERIODICITY: [
    "Journalier",
    "Hebdomadaire",
    "Mensuel",
    "Trimestriel",
    "Annuel",
    "Quinquennal",
  ],
  DEVISES: [
    { id: "flag-icon flag-icon-gb", currency: "Pound", currencyEn: "Pound" },
    {
      id: "flag-icon flag-icon-tn",
      currency: "Dinar Tunisien",
      currencyEn: "Tunisian Dinar",
    },
    { id: "flag-icon flag-icon-eu", currency: "Euro", currencyEn: "Euro" },
    { id: "flag-icon flag-icon-us", currency: "Dollar", currencyEn: "Dollar" },
  ],
  BILLING: ["Paliers", "Forfait mensuel", "Forfait journalier"],
  BILLINGEn: ["Rate limit", "Monthly flat fee", "Daily flat fee"],
};
export const errorCodes = {
  "auth/user-not-found": "Aucun compte n'est associé à cette adresse e-mail.",
  "auth/invalid-email": "L'adresse email saisie est invalide.",
  "auth/user-disabled":
    "Le compte associé à cette adresse e-mail a été désactivé.",
  "auth/wrong-password": "Mot de passe incorrect. Veuillez réessayer.",
  "auth/user-token-expired":
    "Votre session a expirée. Veuillez vous reconnecter.",
  "auth/invalid-user-token":
    "Votre session a expirée. Veuillez vous reconnecter.",
  "auth/network-request-failed":
    "Impossible de communiquer avec le serveur. Veuillez vérifier votre connexion à internet.",
};
export const shipOwnerLogo = {
  MSK: "../../../assets/images/Logo maersk.png",
  // CMA: "../../../assets/images/logo cma-cgm.png",
  CMA: "../../../assets/images/logo-cma.png",
  EVE: "../../../assets/images/evergreen_logo.png",
  OCL: "../../../assets/images/logo OOCL.png",
  ONE: "../../../assets/images/ONE logo.png",
  HPL: "../../../assets/images/logo_HLAG.png",
  YML: "../../../assets/images/YML.png",
  MAR: "../../../assets/images/MAR.png",
  HMM: "../../../assets/images/HMM.png",
  CSO: "../../../assets/images/CSO.png",
  ARN: "../../../assets/images/arnal.png",
  GMP: "../../../assets/images/gmp.jpg",
  WEC: "../../../assets/images/WEC.png",
  REM: "../../../assets/images/REM.png",
  CPT: "../../../assets/images/CPT.png",
  HON: "../../../assets/images/HON.png",
  HBB: "../../../assets/images/HBB.png",
  BLU: "../../../assets/images/BLU.png",
  NOR: "../../../assets/images/NOR.png",
  SEA: "../../../assets/images/SEA.png",
  MEL: "../../../assets/images/MEL.png",
  ZIM: "../../../assets/images/ZIM.png",
  COL: "../../../assets/images/COL.png",
  ALT: "../../../assets/images/ALT.png",
  MSC: "../../../assets/images/MSC.png",
};

export const pays = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
