import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DataAccessHelpersService } from "./data-access-helpers.service";

@Injectable({
  providedIn: "root",
})
export class ClientAccessService {
  /**
   * Returns Client data by id
   * @param parc Parc Id
   * @param clientId Client Id
   */
  getClientData(parc: string, clientId: string): Promise<IClient> {
    return this.dhs.getDocumentOnce(`yards/${parc}/clients/${clientId}`);
  }
  /**
   * Returns all clients belongs to a parc
   * @param parc Parc Id
   */
  getAllClients(parc: string): Promise<IClient[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/clients`);
  }
  /**
   * Returns all suppliers belongs to a parc
   * @param parc Parc Id
   */
  getAllFournisseurs(parc: string): Promise<IFournisseur[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/fournisseurs`);
  }
  updateClient(parcId: string, clientId: string, data: Partial<IClient>) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("clients")
      .doc(clientId)
      .update(data);
  }
  /**
   * Returns all trucking suppliers belongs to a parc
   * @param parc Parc Id
   */
  getTruckingFournisseur(parc: string): Observable<IFournisseur[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/fournisseurs`, [
      { left: "activity", operator: "==", right: "Transporter" },
    ]);
  }
  /**
   * Get Shunting contract of a client
   * @param parc  Parc Id
   * @param client Client Id
   */
  getClientShuntingBilling(
    parc: string,
    client: string
  ): Promise<IShuntingContract> {
    return this.dhs.getDocumentOnce(
      `yards/${parc}/clients/${client}/depotcontracts/shuntingContract`
    );
  }
  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore
  ) {}
}
