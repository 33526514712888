// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC37gfUbLLVGRg4AIBMyxVpqGA9Noc1O4A",
    authDomain: "shipzzer.firebaseapp.com",
    databaseURL: "https://shipzzer.firebaseio.com",
    projectId: "shipzzer",
    storageBucket: "shipzzer.appspot.com",
    messagingSenderId: "674325335279",
  },
  GOOGLE_MAPS_API_KEY: "AIzaSyCc9XvdO1XgsiSYufdlHsBuSaCJactVt-M",

API_ENDPOINT: "https://staging-dot-f619-224415.ey.r.appspot.com",
 //  API_ENDPOINT: "http://localhost:8080",
 // API_ENDPOINT: "https://f619-224415.ey.r.appspot.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
